import { Concept } from 'fieldFactory/input/components/Concept';
import { IntlShape } from 'react-intl';
import ViewConfig from 'reducers/ViewConfigType';
import { CasetivityConfigRootViewContextVariable } from 'util/casetivityViewContext';
import { ValueSets } from 'valueSets/reducer';
import f from 'f';
import { Frontend } from './content/index';
import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import { userAgent } from 'userAgent';

const { CurrentUserContext, EntityAndConceptLookupContext, LocaleContext, ...methods } = Frontend;

const getMethodsFromInstance = <Obj extends {}>(obj: Obj) => {
    const res = {};
    Object.keys(obj).forEach((k) => {
        const item = obj[k];
        if (typeof item === 'function') {
            res[k] = item;
        }
    });
    return res;
};
const browserContextProperties = {
    _isIos: userAgent.isIos(),
    _userAgent: navigator.userAgent,
    _isAndroid: userAgent.isAndroid(),
};

/**
 *
 * @param args All the properties in-scope we want to make available.
 * As we pass the necessary data, the methods depending on them will appear in the context.
 * @returns A near-complete SPEL context. Some things like getVar/getVars need to be overridden, and are present only as default-behavior placeholders.
 */

export const buildContext = (args: {
    viewConfig?: ViewConfig;
    entities?: Record<string, Record<string, unknown>> & {
        Concept?: {
            [id: string]: Concept;
        };
    };
    valueSets?: ValueSets;
    viewContext?: CasetivityConfigRootViewContextVariable;
    intl?: IntlShape;
    extra?: {};
    backref?: {
        id: string;
        entityType: string;
        path: string;
    };
}) => {
    const { entities, valueSets, viewConfig, viewContext, intl, extra, backref } = args;
    const options = { viewContext, dateFormat: viewConfig?.application?.dateFormat || 'MM/dd/yyyy', backref };

    return {
        ...methods,
        ...getMethodsFromInstance({
            // We need to process these objects to remove properties (we only want methods)
            ...(viewConfig && new CurrentUserContext(viewConfig.user)),
            ...(viewConfig &&
                valueSets &&
                entities &&
                new EntityAndConceptLookupContext({
                    valueSets,
                    entities,
                    viewConfig,
                })),
            ...new LocaleContext(intl),
        }),
        f,
        roles: viewConfig?.user?.roles ?? [],
        options,
        viewContext,
        ...createRootContext({ options }),
        ...browserContextProperties,
        ...extra,
        getBackref: () => backref ?? null,
    };
};
