import { useMemo, useCallback, useRef } from 'react';
import { ExpressionEvaluatorContext } from '../expressionEvaluatorContext';
import useExpressionEval from './useExpressionEval';
import KeyCachingEvaluator, { CachingEvaluator, Expressions, Evaler } from '../../CachingEvaluator/Evaluator';
import { keysToClearValuesetCachedResults } from '../../CachingEvaluator/FormContextEvaluator';
import useEvalContext from './useEvalContext';
import fromEntries from 'util/fromentries';
import { identity } from 'fp-ts/lib/function';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { accumulateExpressions, parse } from './accumulateExpressions';

export const useEvalFn = (ec: ExpressionEvaluatorContext) =>
    useCallback(
        (expression: string) => {
            const compiled = ec.compileExpression(expression);
            if (compiled.type === 'parse_failure') {
                throw new Error('Failed to compile "' + expression + '": ' + compiled.msg);
            }
            const evaler: Evaler = (() => {
                const _evaler = function (context: {}) {
                    return (values: {}) => {
                        const result = compiled.evaluate(
                            {
                                ...context,
                                ...values,
                            },
                            context,
                        );
                        if (result.type === 'evaluation_failure') {
                            throw new Error('Failed to evaluate "' + expression + '": ' + result.msg);
                        }
                        return result.result;
                    };
                } as Evaler;
                _evaler.dataPathTrie = compiled.getDataPathTrie?.();
                _evaler.methodsRequired = compiled.methodsAndFunctions;
                _evaler.fieldsRequired = compiled.getExpansionsWithoutArrayDescendants();
                return _evaler;
            })();
            return evaler;
        },
        [ec],
    );

export const clearCachesForEvaluator = (e: KeyCachingEvaluator<any> | CachingEvaluator, newContext: {}) => {
    e.clearCaches((expression) => {
        return (
            expression.includes('lookupEntityData') ||
            expression.includes('mapToEntityData') ||
            expression.includes('Code') ||
            keysToClearValuesetCachedResults.some((x) => expression.includes(x)) ||
            expression.includes('getVar(') ||
            expression.includes('getVars(')
        );
    }, newContext);
};
const useKeyCachingEval = <E extends Expressions>(expressions: E) => {
    const ec = useExpressionEval();
    const context = useEvalContext();

    const evalFn = useEvalFn(ec);
    // instead of regenerating this on eval function change (really, 'context' change)
    // lets clear caches for possibly effected expressions.
    const evaluator = useMemo(() => new KeyCachingEvaluator(expressions, evalFn, context, 'deepeql'), [expressions]); // eslint-disable-line
    useMemo(() => {
        clearCachesForEvaluator(evaluator, context);
    }, [context, evaluator]);
    return evaluator.evaluateAll;
};

// use this in cases where expressions are going to be added dynamically
// (we do so by adding a new keycaching evaluator for
// the positive diff between the expressions argument every time it changes)
// Note: We don't have a 'remove' implemented.

// below allows injection of evaluator and context for testing.
export const _useDynamicKeyCachingEvaluator = <E extends Expressions>(
    context: {},
    ec: ExpressionEvaluatorContext,
    expressions: E,
) => {
    const evalFn = useEvalFn(ec);
    const initialKeyCachingEvaluator = useMemo(
        () => new KeyCachingEvaluator(expressions, evalFn, context, 'deepeql'),
        [], // eslint-disable-line
    );
    const _keyCachingEvaluator = useRef<KeyCachingEvaluator<E>>(initialKeyCachingEvaluator);
    // const oldExpressions = useRef(expressions);
    useMemo(() => {
        clearCachesForEvaluator(_keyCachingEvaluator.current, context);
    }, [context]);
    const prevExpressions = useRef(expressions);
    useMemo(() => {
        if (prevExpressions.current !== expressions) {
            _keyCachingEvaluator.current.changeExpressions(expressions, evalFn, context);
            prevExpressions.current = expressions;
        }
    }, [expressions, context, evalFn]);
    return useCallback((values: {}) => {
        return _keyCachingEvaluator.current.evaluateAll(values);
    }, []);
};

/*
    user-land hook
*/
export const useDynamicKeyCachingEvaluator = <E extends Expressions>(expressions: E) => {
    const ec = useExpressionEval();
    const context = useEvalContext();
    return _useDynamicKeyCachingEvaluator(context, ec, expressions);
};

type RecursiveAny<T> = {
    [P in keyof T]: T[P] extends [infer U]
        ? [RecursiveAny<U>]
        : T[P] extends (infer U)[]
        ? RecursiveAny<U>[]
        : T[P] extends object
        ? RecursiveAny<T[P]>
        : any;
};
export const _useDynamicKeyCachingEvaluators = <
    E extends Expressions,
    K extends {
        [keyToEvaler: string]: E;
    },
>(
    context: {},
    ec: ExpressionEvaluatorContext,
    expressionss: K,
    deleteRemoved: boolean = false,
) => {
    type Result = {
        [key in keyof K]: (values: {}) => RecursiveAny<K[key]>;
    };
    const evalFn = useEvalFn(ec);
    const initialKeyCachingEvaluators = useMemo(
        () =>
            fromEntries(
                Object.entries(expressionss).map(([key, expressions]) => [
                    key,
                    new KeyCachingEvaluator(expressions, evalFn, context, 'deepeql'),
                ]),
            ),
        [], // eslint-disable-line
    );
    const _keyCachingEvaluators = useRef<{
        [key: string]: KeyCachingEvaluator<Expressions>;
    }>(initialKeyCachingEvaluators);
    useMemo(() => {
        Object.values(_keyCachingEvaluators.current).forEach((evaluator) => {
            clearCachesForEvaluator(evaluator, context);
        });
    }, [context]);
    const prevExpressionss = useRef(expressionss);
    useMemo(() => {
        if (prevExpressionss.current !== expressionss) {
            Object.keys(_keyCachingEvaluators.current).forEach((key) => {
                if (!expressionss[key]) {
                    if (deleteRemoved) {
                        // we usually don't want to do this with every expression change, for example on validations
                        // we want to prevent thrashing (deleting and re-initializing)
                        delete _keyCachingEvaluators.current[key];
                    }
                } else {
                    // update those kept
                    _keyCachingEvaluators.current[key].changeExpressions(expressionss[key], evalFn, context);
                }
            });
            Object.keys(expressionss).forEach((key) => {
                if (!_keyCachingEvaluators.current[key]) {
                    _keyCachingEvaluators.current[key] = new KeyCachingEvaluator(
                        expressionss[key],
                        evalFn,
                        context,
                        'deepeql',
                    );
                }
            });
            prevExpressionss.current = expressionss;
        }
    }, [expressionss, context, deleteRemoved, evalFn]);
    const res: Result = useMemo(() => {
        return fromEntries(
            Object.keys(expressionss).map((k) => {
                return [k, _keyCachingEvaluators.current[k].evaluateAll];
            }),
        ) as any;
    }, [expressionss]);
    return res;
};

export const useDynamicKeyCachingEvaluators = <
    E extends Expressions,
    K extends {
        [keyToEvaler: string]: E;
    },
>(
    expressionss: K,
    overrideEntities?: any,
    extraContext?: Record<string, unknown>,
) => {
    const ec = useExpressionEval();
    const context = useEvalContext(overrideEntities);
    const combinedContext = useMemo(() => {
        return { ...context, ...extraContext };
    }, [context, extraContext]);
    return _useDynamicKeyCachingEvaluators(combinedContext, ec, expressionss);
};

// helper for common use case of just a single expression (that also could be a boolean)
export const useSingleKeyCachingExpression = (
    expression: string | boolean | undefined,
    extraContext?: {},
    defaultTo?: any,
) => {
    const _exps = useMemo(
        () => ({
            expressions: typeof expression === 'string' ? [expression] : [],
        }),
        [expression],
    );
    const evaluate = useKeyCachingEval(_exps);
    const result = useMemo(() => {
        if (typeof expression === 'boolean') {
            return expression;
        }
        const { expressions = [] } = evaluate(extraContext || {});
        return expressions[0];
    }, [expression, extraContext, evaluate]);
    if (typeof result === 'undefined') {
        //i.e. type of expression isn't a string or boolean
        return defaultTo;
    }
    return result;
};

export const useSingleKeyCachingEvaluator = <T>(expression: string | boolean | undefined): ((values?: {}) => T) => {
    const _exps = useMemo(
        () => ({
            expressions: typeof expression === 'string' ? [expression] : [],
        }),
        [expression],
    );
    const evaluate = useKeyCachingEval(_exps);

    const evaluator = useCallback(
        (values: {} = {}) => {
            if (typeof expression === 'boolean') {
                return expression;
            }
            const { expressions = [] } = evaluate(values);
            return expressions[0];
        },
        [evaluate, expression],
    );
    return evaluator;
};

export const _useEvaluateTemplate = (
    _template: string,
    baseContext: {},
    ec: ExpressionEvaluatorContext,
    sanitizeResult: (result: string) => string = identity,
) => {
    const evalFn = useEvalFn(ec);

    const template = useEvaluatedFormattedMessage(_template);

    const evaluator = useMemo(() => {
        const expressions = accumulateExpressions(template);
        return new CachingEvaluator(expressions, evalFn, baseContext, 'deepeql', (expression) => (values) => '');
    }, [template]); // eslint-disable-line

    useMemo(() => {
        clearCachesForEvaluator(evaluator, baseContext);
    }, [evaluator, baseContext]);

    return useCallback(
        (values: {}) => {
            const resultMap = evaluator.evaluateAll(values);
            const evalString = (str: string) =>
                parse(str).fold(str, ({ before, inner, after }) => {
                    const result = resultMap[inner];
                    const sanitizedResult = sanitizeResult(result ? result.toString() : '');
                    return `${before}${sanitizedResult}${evalString(after)}`;
                });
            return evalString(template);
        },
        [evaluator, template, sanitizeResult],
    );
};

export const useEvaluateTemplate = (template: string, sanitizeResult: (result: string) => string = identity) => {
    const ec = useExpressionEval();
    const baseContext = useEvalContext();
    return _useEvaluateTemplate(template, baseContext, ec, sanitizeResult);
};

export default useKeyCachingEval;
